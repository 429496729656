<template>
    <v-layout>
        <NuxtErrorBoundary>
            <v-main>
                <slot />
            </v-main>
            <template #error>
                <NavigationBarError />
            </template>
        </NuxtErrorBoundary>
    </v-layout>
</template>
